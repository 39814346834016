import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFeedBack } from "../../contexts/FeedBackContext";
import useAxios from "../../hooks/useAxios";
import useUsers from "../../hooks/useUsers";
import AsyncSelect from 'react-select/async';
import mapValues from "../../utils/mapValues";
import handleLoadSelectOptions from "../../utils/loadSelectValues";
import useCommunes from "../../hooks/useCommunes";

const CostCentersUpdate = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { setCustomAlert, setLoading } = useFeedBack();

    const [firstLoading, setFirstLoading] = useState(true);

    const filters = {
        perPage: 200,
        page: 1
    };

    const [data, setData] = useState({
        name: '',
        commune: '',
        boss: '',
        code: '',
        type: '',
        document_prefix: ''
    });

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ communes, loading: communesLoading }, getCommunes] = useCommunes({ axiosConfig: { params: { ...filters } }, options: { useCache: false, manual: true } });

    const [{ data: dataToUpdate, error: dataToUpdateError, loading: dataToUpdateLoading }] = useAxios({ url: `/cost-centers/${id}` }, { useCache: false });

    const [{ data: updateData, loading: updateLoading, error: updateError }, updateRecord] = useAxios({ url: `/cost-centers/${id}`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (dataToUpdate) {
            setData((oldData) => {
                return {
                    ...oldData,
                    name: dataToUpdate?.data?.name,
                    code: dataToUpdate?.data?.code,
                    type: dataToUpdate?.data?.type || '',
                    document_prefix: dataToUpdate?.data?.document_prefix || '',
                    boss: dataToUpdate?.data?.boss ? { label: dataToUpdate?.data?.boss?.name, value: dataToUpdate?.data?.boss?.id } : '',
                    commune: dataToUpdate?.data?.commune ? { label: dataToUpdate?.data?.commune?.name, value: dataToUpdate?.data?.commune?.id } : ''
                }
            });
        }
    }, [dataToUpdate]);

    useEffect(() => {
        if (!dataToUpdateLoading) {
            setFirstLoading(false);
        } else {
            setFirstLoading(true)
        }
    }, [dataToUpdateLoading]);

    useEffect(() => {
        setLoading({
            show: firstLoading,
            message: 'Obteniendo informacion'
        });
    }, [firstLoading]);

    useEffect(() => {
        if (updateData) {
            setCustomAlert({
                title: '¡Operacion Exitosa!',
                severity: 'success',
                message: 'El registro fue actualizado exitosamente.',
                show: true
            });
            navigate('/centro-de-costos');
        }
    }, [updateData])

    useEffect(() => {
        if (updateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error.',
                show: true
            });
        }

        if (dataToUpdateError) {
            setCustomAlert({
                title: 'Error',
                severity: 'danger',
                message: 'Ha ocurrido un error al obtener los datos.',
                show: true
            });
        }
    }, [updateError])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (updateLoading) {
            return;
        }

        const dataToSend = {
            name: data?.name,
            code: data?.code,
            type: data?.type,
            document_prefix: data?.document_prefix,
            bossId: data?.boss?.value,
            communeId: data?.commune?.value,
        }

        updateRecord({ data: dataToSend });
    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {
            const value = data[e.target.name]?.includes(e.target.value);
            if (value) {
                const newValues = data[e.target.name]?.filter(n => n !== e.target.value);
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: newValues
                    }
                });
            } else {
                setData((oldData) => {
                    return {
                        ...oldData,
                        [e.target.name]: [...data[e.target.name], e.target.value]
                    }
                });
            }
            return;
        }


        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
            }
        })
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Actualizar Centro de Costo</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-5">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        name="name"
                                        value={data?.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Codigo</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="code"
                                        placeholder="Codigo"
                                        value={data?.code}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Tipo</label>
                                    <select name="type" className="form-control" onChange={handleChange} value={data?.type} >
                                        <option value="" disabled>Seleccione una opción</option>
                                        <option value="service">Servicio</option>
                                        <option value="cost_center">Centro de costo</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Prefijo para documentos</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="document_prefix"
                                        placeholder="prefijo"
                                        value={data?.document_prefix}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Jefe del centro de costo</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getUsers();
                                        }}
                                        value={data?.boss}
                                        defaultOptions={mapValues(users)}
                                        isLoading={usersLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getUsers)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'boss' } }) }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Comuna</label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => {
                                            getCommunes();
                                        }}
                                        value={data?.commune}
                                        defaultOptions={mapValues(communes)}
                                        isLoading={communesLoading}
                                        loadOptions={(e) => handleLoadSelectOptions(e, getCommunes)}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => { handleChange({ target: { value: e, name: 'commune' } }) }}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex justify-content-end">
                                <Link to={`#`} onClick={() => { navigate(-1) }} className="btn btn-danger mx-2">
                                    Cancelar
                                </Link>
                                <button disabled={updateLoading} type="submit" className="btn btn-primary mx-2">
                                    {
                                        updateLoading ?
                                            'Cargando'
                                            :
                                            'Actualizar'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CostCentersUpdate;